import BButton from 'components/common/BButton';
import TranslatedLink from 'components/common/TranslatedLink';
import NotFoundIllustration from 'components/Icons/illustrations/notFound.svg';
import { useLocale } from 'hooks/useLocale';
import type { NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import { linkService } from 'services/LinkService';

const Custom404: NextPage = () => {
  const { t } = useTranslation('404');
  const { locale } = useLocale();

  const links = [
    { label: 'mainPage', href: '/' },
    { label: 'organizations', href: '/hizmetler' },
    { label: 'allBoats', href: linkService.generateCategoryLink({ locale }) },
    { label: 'motoryachtCharter', href: `/${t('common:boatLinks.YACHT')}` },
    { label: 'boatCharter', href: `/${t('common:boatLinks.INVITATION_BOAT')}` },
    {
      label: 'istanbulRental',
      href: linkService.generateCategoryLink({
        locale,
        locationSlug: 'istanbul',
      }),
    },
  ];

  return (
    <div className="text-center">
      <h1 className="mb-6 text-xl font-semibold">{t('title')}</h1>
      <p className="mb-6 text-lg text-slate-500">{t('description')}</p>
      <NotFoundIllustration className="mx-auto mb-8 max-w-xs" />
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
        {links.map((link) => (
          <TranslatedLink key={link.label} href={link.href}>
            <a>
              <BButton priority="outlined" className="w-56">
                {t(link.label)}
              </BButton>
            </a>
          </TranslatedLink>
        ))}
      </div>
    </div>
  );
};

export default Custom404;
